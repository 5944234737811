<template>
    <div v-show="isExceptionPickerVisible"
        class="z-20 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80">
        <div class="rounded-lg border-[#4dd89f] border-2 h-fit w-[80%] md:w-[400px] p-4 bg-[#0F2B24]">
            <label class="block text-white  mb-2">
                Horário
            </label>

            <select v-model="selectedTime"
                class="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500">
                <option v-for="timeInterval in timeIntervals" :key="timeInterval" :value="timeInterval">
                    {{ timeInterval }}
                </option>
            </select>

            <label class="block text-white  mt-4 mb-2" for="exceptionStatus">
                Estado:
            </label>

            <select v-model="exceptionStatus"
                class="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500">
                <option value="open">Aberto</option>
                <option value="closed">Fechado</option>
            </select>

            <button @click="addToExceptionArray" :class="{ 'button-disable': !this.selectedDate }"
                class="mt-4 bg-[#4dd89f] hover:bg-[#38a176] text-black text-xl font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                Adicionar Dia
            </button>
            <div class="mt-4 text-white p-2 border-2 border-[#4dd89f] rounded"
                style="max-height: 25rem; overflow-y: auto;">
                <p class="opacity-50" v-if="exceptionalTimes.length <= 0">
                    Lista Vazia
                </p>
                <ul class="flex flex-col gap-y-2">
                    <li v-for="exception in exceptionalTimes" :key="exception._id"
                        class="flex items-center justify-between exception-wraper"
                        :class="{ 'bg-green-700': exception.status === 'open', 'bg-red-700': exception.status === 'closed' }">
                        <div class="flex flex-col items-start">
                            <span style="font-size: 0.9em;">{{ formatDate(exception.date) }} - {{ exception.startTime
                                }}</span>
                            <p class="text-xs opacity-50">{{ exception.barberName }}</p>
                        </div>


                        <div class="flex gap-x-5 items-center">
                            <span></span>
                            <button @click="deleteException(exception)"
                                class="bg-white hover:bg-red-500 hover:text-white text-black px-2 py-2 text-sm font-medium  rounded-md">
                                Apagar
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <button @click="closeModal"
                class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                Fechar
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // selectedDate: null,
                selectedTime: null,
                timeIntervals: [
                    "Manhã",
                    "Tarde",
                    "Dia Completo",
                    "9:30",
                    "10:15",
                    "11:00",
                    "11:45",
                    "12:30",
                    "14:30",
                    "15:15",
                    "16:00",
                    "16:45",
                    "17:30",
                    "18:15",
                    "19:00",
                ],
                exceptionStatus: "open",
                barbers: [
                    { id: 1, name: "Beto Almeida" },
                    { id: 2, name: "Bruno Rocha" },
                ],
            };
        },
        props: {
            exceptionalTimes: Array,
            isExceptionPickerVisible: Boolean,
            selectedDate: String
        },
        emits: ["add-exception", "delete-exception", "close-modal"],
        methods: {
            addToExceptionArray() {
                if (this.selectedDate && this.selectedTime) {
                    const newException = {
                        date: this.selectedDate,
                        startTime: this.selectedTime,
                        status: this.exceptionStatus,
                    };

                    this.$emit("add-exception", newException);

                    // this.selectedDate = null;
                    this.selectedTime = null;
                    this.isVisible = false;
                }
            },
            deleteException(exception) {
                this.$emit("delete-exception", exception);
            },
            formatDate(dateString) {
                const options = { day: "2-digit", month: "2-digit", year: "numeric" };
                const formattedDate = new Date(dateString).toLocaleDateString(
                    "pt-PT",
                    options
                );
                return formattedDate;
            },
            closeModal() {
                this.$emit("close-modal");
            },
        },
    };
</script>

<style scoped lang="postcss">
    input {
        cursor: pointer;
    }

    input::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }

    .exception-wraper {
        border-radius: .5em;
        padding: .2em;
    }

    .dot-green {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
    }

    .dot-red {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
    }

    .button-disable {
        pointer-events: none;
        opacity: 20%;
    }
</style>
