<template>
    <DatePicker @update:pages="emitMonthChanged" ref="datePickerRef" :first-day-of-week="2" borderless transparent
        :is-dark=true v-model="date" :disabled-dates="disabledDates" :attributes="attributes" locale="pt-PT"
        color="orange" class="w-full flex-1 bg-[#141414] rounded-lg border-2 border-[#4dd89f]">
        <template #footer>
            <div class="flex gap-x-4 w-full px-4 pb-3">
                <button
                    class="bg-[#e7d3cc] hover:bg-[#fae4dd] text-lg text-black font-bold w-full px-3 py-1 rounded-md border border-[#ffffff]"
                    @click="moveToday">
                    Hoje
                </button>
                <button @click="setExceptionCalendarVisibility"
                    class="px-2 py-2 rounded-md bg-[#0F2B24] hover:bg-[#18463a] border-2 border-[#4dd89f] ">
                    <img src="@/assets/calendar.svg" alt="">
                </button>
            </div>
        </template>

    </DatePicker>
</template>
<script>

    import { DatePicker } from 'v-calendar';
    import 'v-calendar/style.css';

    export default {
        components: {
            DatePicker,
        },
        emits: ['update-date', 'update-calendar', 'update-calendar-month', 'toggle-exception-date'],
        props: {
            appointmentsData: {
                type: Object,
                default: () => ({ counts: [], dates: [] })
            },
            exceptionalTimes: Array
        },
        data() {
            return {
                date: new Date(),
                disabledDates: [],
                previous_data: [],
            }
        },

        computed: {
            attributes() {
                return this.appointmentsData.dates.map((date, index) => {
                    const count = this.appointmentsData.counts[index].count;
                    const color = this.getDotColor(count);

                    return {
                        dates: new Date(date),
                        dot: { color },

                        highlight: {
                            style: {
                                backgroundColor: '#0b493a'
                            },
                            fillMode: 'light',
                        },
                        content: {
                            style: {
                            }
                        },

                    };
                });
            },

        },
        watch: {
            date(newDate) {
                this.$emit('update-date', newDate);
            },
            exceptionalTimes: {
                handler() {
                    this.setDisabledDates();
                },
                immediate: true,
            },
        },
        created() {
            this.setDisabledDates();
        },
        methods: {
            moveToday() {
                this.date = new Date();
                this.$refs.datePickerRef.move(this.date)
            },
            getDotColor(count) {
                if (count <= 3) {
                    return "green";
                } else if (count <= 5) {
                    return "yellow";
                } else if (count <= 8) {
                    return "orange";
                } else if (count <= 11) {
                    return "red";
                } else if (count == 12) {
                    return "purple";
                }
            },
            setDisabledDates() {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                const disabledDates = [];

                for (let i = 0; i < 365; i++) {
                    const date = new Date();
                    const date_previous = new Date();
                    date.setDate(currentDate.getDate() + i);
                    date_previous.setDate(currentDate.getDate() - i);
                    date.setHours(0, 0, 0, 0);

                    if (this.exceptionalTimes) {
                        const isExceptionalTimeClosed = this.exceptionalTimes.some(
                            (exceptionalTime) =>
                                this.isSameDate(date, exceptionalTime.date) && exceptionalTime.status === 'open'
                        );

                        // const isExceptionalTimeOpen = this.exceptionalTimes.some(
                        //     (exceptionalTime) =>
                        //         this.isSameDate(date, exceptionalTime.date) && exceptionalTime.status === 'closed'
                        // );

                        if (isExceptionalTimeClosed) {
                            const index = disabledDates.indexOf(date);
                            if (index !== -1) {
                                disabledDates.splice(index, 1); // Remove the date from disabledDates
                            }
                        }
                        // else if (isExceptionalTimeOpen) {
                        //     disabledDates.push(date);
                        // }
                    }
                }

                this.disabledDates = disabledDates;
            },
            isSameDate(date1, date2) {
                const formattedDate = new Date(date2)
                return (
                    date1.getFullYear() === formattedDate.getFullYear() &&
                    date1.getMonth() === formattedDate.getMonth() &&
                    date1.getDate() === formattedDate.getDate()
                );
            },
            emitMonthChanged(data) {
                if (data != this.previous_data) {
                    this.$emit('update-calendar-month', data);
                    this.previous_data = data
                }
            },
            setExceptionCalendarVisibility() {
                this.$emit('toggle-exception-date')
            },


        }
    }
</script>

<style lang="postcss">
    .vc-day-content {
        font-size: 20px;
    }

    .vc-highlight {
        width: 40px;
        height: 40px;
    }

    .vc-focus:focus-within {
        box-shadow: none;
    }

    .vc-day {
        min-height: 44px;
    }

    .vc-title span::first-letter {
        text-transform: uppercase;
    }

    .vc-day-content:focus {
        outline: none;
    }

    .vc-day-layer {
        bottom: -2px;
    }

    .vc-day-content:hover {
        background-color: transparent;
    }
</style>