<!-- ConfirmationModal.vue -->
<template>
    <div v-if="show" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="absolute w-full h-full bg-black opacity-40"></div>
        <div class="bg-white py-8 px-12 mx-auto rounded-md text-center z-10 shadow-md">
            <h2 class="text-xl font-bold mb-6">{{ headerText }}</h2>
            <div class="flex justify-center space-x-4">
                <button @click="onConfirm"
                    class=" text-white bg-red-600 px-4 py-2 rounded-md hover:bg-red-700 active:bg-red-800 focus:outline-none focus:shadow-outline-red">
                    Confirmar
                </button>
                <button @click="onCancel"
                    class=" text-white bg-gray-600 px-4 py-2 rounded-md hover:bg-gray-700 active:bg-gray-800 focus:outline-none focus:shadow-outline-gray">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ConfirmationModal",
        props: {
            show: Boolean,
            headerText: String,
            confirm: Function,
            cancel: Function,
        },
        emits: ['confirm', 'close'],
        methods: {
            onCancel() {
                this.$emit("close");
            },
            onConfirm() {
                this.$emit("confirm");
            },
        },
    };
</script>