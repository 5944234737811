<template>
    <ConfirmationModal :show="showCancelConfirmModal" headerText="Tem certeza que deseja cancelar a marcação?"
        @confirm="confirmCancelAppointment" @close="closeCancelConfirmModal" />
    <div class="flex-1 overflow-y-auto overflow-x-auto max-h-full rounded-lg border-[#4dd89f] border-2">
        <table class="min-w-[1000px] divide-y divide-gray-300 md:w-full border-spacing-y-[3px] text-white">
            <thead class="max-h-5 bg-[#141414] border-b border-transparent">
                <tr class=" text-lg">
                    <th class="px-4 py-3 font-bold">Hora</th>
                    <th class="px-4 py-3 font-bold">Nome</th>
                    <th class="px-4 py-3 font-bold">Contacto</th>
                    <th class="px-4 py-3 font-bold"></th>
                    <th class="px-4 py-3 font-bold">Compareceu?</th>
                    <th class="px-4 py-3 font-bold">Notas</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="appointment in sortedAppointmentInfo" :key="appointment.date + appointment.time"
                    class="text-xl"
                    :class="{ '!bg-green-900': appointment.clientPresent === true, '!bg-red-900': appointment.clientPresent === false }">
                    <td class="px-4 py-3"> <!-- Merge the date and time cells -->
                        <!-- <div>{{ new Date(appointment.date).toISOString().substring(0, 10) }}</div> -->
                        <div>{{ appointment.time }}</div>
                    </td>
                    <td class="px-4 py-3">{{ appointment.customerName }}</td>
                    <td class="px-4 py-3"><a :href="'tel:' + appointment.customerPhone"
                            class="text-blue-500 hover:underline">
                            {{ appointment.customerPhone }}</a>
                    </td>
                    <td class="px-4 py-3">
                        <button @click="cancelAppointment(appointment)"
                            :disabled="isAppointmentInPast(appointment) || appointment.clientPresent"
                            class="px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150  border border-transparent rounded-md focus:outline-none focus:shadow-outline-red"
                            :class="{ 'bg-red-600 active:bg-red-600 hover:bg-red-700 ': !isAppointmentInPast(appointment) && !appointment.clientPresent }">
                            Cancelar
                        </button>
                    </td>
                    <td class="px-4 py-3" :class="{ 'space-x-4': appointment.clientPresent == null }">
                        <button v-if="appointment.clientPresent == null"
                            @click="updateClientPresence(appointment, true)"
                            class="w-10 h-10 leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-full active:bg-green-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-green text-xl font-bold">
                            &check;
                        </button>
                        <button v-if="appointment.clientPresent == null"
                            @click="updateClientPresence(appointment, false)"
                            class="w-10 h-10 text-xl leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-full active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-red">
                            X
                        </button>
                        <p class="" v-if="appointment.clientPresent == true">Sim</p>
                        <p class="" v-if="appointment.clientPresent == false">Não</p>
                    </td>
                    <td class="px-4 py-3">
                        <button v-if="appointment.notes" @click="openModal(appointment.notes)"
                            class="mx-auto px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue">
                            Ver Nota
                        </button>
                        <div v-if="activeNote" class="fixed inset-0 flex items-center justify-center z-50">
                            <div class="absolute w-full h-full bg-black opacity-20"></div>
                            <div class="bg-white p-4 max-w-xl mx-auto rounded-md text-center z-10">
                                <h2 class="font-bold mb-4">Nota do Cliente</h2>
                                <p class="text-black">{{ activeNote }}</p>
                                <button @click="closeModal()" class="mt-6 text-blue-700">Fechar</button>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr v-if="appointmentInfo.length === 0" class="text-2xl text-center text-white">
                    <td colspan="6" class="px-4 py-3">Sem Marcações!</td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script>

    import ConfirmationModal from "@/components/ConfirmationModal.vue";
    // eslint-disable-next-line no-unused-vars
    import axios from "axios"

    export default {
        name: 'TableComponent',
        components: {
            ConfirmationModal,
        },
        props: {
            appointmentInfo: Array,
            selectedDate: Date,
        },
        data() {
            return {
                activeNote: null,
                showCancelConfirmModal: false,
                cancellingAppointment: null,
            }
        },
        emits: ['update-appointment'],
        computed: {
            sortedAppointmentInfo() {
                return this.appointmentInfo.slice().sort((a, b) => {
                    const aDateTime = this.parseAppointmentDateTime(a.date, a.time);
                    const bDateTime = this.parseAppointmentDateTime(b.date, b.time);
                    return aDateTime - bDateTime;
                });
            },
        },
        methods: {
            cancelAppointment(appointment) {
                this.cancellingAppointment = appointment;
                this.showCancelConfirmModal = true;
            },
            closeCancelConfirmModal() {
                this.showCancelConfirmModal = false;
                this.cancellingAppointment = null;
            },
            parseAppointmentDateTime(date, time) {
                const [hours, minutes] = time.split(':');
                const dateTime = new Date(date);
                dateTime.setHours(hours, minutes);
                return dateTime;
            },
            async confirmCancelAppointment() {
                const appointment = this.cancellingAppointment;
                this.showCancelConfirmModal = false;
                try {
                    const response = await axios.delete(`${process.env.VUE_APP_API_URL}/api/appointments/${appointment._id}/cancel`, {
                        data: {
                            date: appointment.date,
                            customerEmail: appointment.customerEmail,
                            customerName: appointment.customerName,
                        },
                    });

                    if (response.status === 200) {
                        this.$emit('update-appointment', 'remove', appointment);
                        this.cancellingAppointment = null;
                    } else {
                        console.log('Error cancelling appointment', response);
                    }
                } catch (error) {
                    console.error('Error cancelling appointment:', error);
                }
            },
            async updateClientPresence(appointment, presenceType) {
                const id = appointment._id
                const clientPresent = presenceType

                try {
                    const response = await fetch(`${process.env.VUE_APP_API_URL}/api/appointments/${id}/presence`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ clientPresent }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const data = await response.json();
                    this.$emit('update-appointment', 'updatePresence', { id: data.id, presence: data.clientPresent });
                } catch (error) {
                    console.error("Failed to update presence:", error);
                }
            },
            isAppointmentInPast(appointment) {
                const currentDate = new Date();
                const appointmentDate = new Date(appointment.date);
                appointmentDate.setHours(appointment.time.split(':')[0], appointment.time.split(':')[1]);

                return appointmentDate < currentDate;
            },
            openModal(note) {
                this.activeNote = note;
            },
            closeModal() {
                this.activeNote = null;
            },
        },
    }
</script>

<style lang="postcss" scoped>
    tr:nth-child(even) {
        @apply bg-[#242424];
    }
</style>