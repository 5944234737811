<template>
  <nav class="w-full bg-[#141414] flex justify-between items-center px-6 py-3 text-white ">
    <div class="flex flex-col gap-1 items-start">
      <img src="../assets/logo_thick.svg" class="h-4 md:h-6" alt="">
      <p class="text-white text-xs opacity-50 leading-3">{{ lastUpdate }}</p>
    </div>
    <div class="flex items-center gap-x-2">
      <img v-if="selectedBarber === 'Beto Almeida'" src="@/assets/beto.webp" alt=""
        class=" w-6 h-6 md:w-12 md:h-12 object-fit rounded-full border-2">
      <img v-else src="@/assets/bruno.webp" alt="" class=" w-6 h-6 md:w-12 md:h-12 object-fit rounded-full border-2">
      <div class="select-container">
        <select v-model="selectedBarber" id="barber-selector" @change="emitSelectedBarber"
          class="bg-transparent text-white text-sm md:text-xl rounded-lg focus:ring-[blue-500] focus:border-[blue-500] block w-full pr-8 p-2.5 cursor-pointer">
          <option class="text-black" v-for="barber in barbersList" :key="barber" :value="barber">{{ barber }}</option>
        </select>
      </div>
    </div>

  </nav>
</template>

<script>
  export default {
    name: "NavBar",
    emits: ['update-barber'],
    props: { lastUpdate: String },
    data() {
      return {
        selectedBarber: 'Beto Almeida',
        barbersList: ["Beto Almeida", "Bruno Rocha"],
      };
    },
    methods: {
      emitSelectedBarber() {
        this.$emit("update-barber", this.selectedBarber);
      },
    },
  }
</script>

<style scoped>

  .select-container {
    position: relative;
  }

  .select-container::after {
    content: '';
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat;
    width: 0.8em;
    height: 1em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0.8rem;
    transform: translateY(-50%);
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: max-content;
  }
</style>